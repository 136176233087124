// alpine js
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import header from './alpine-js/header';
// AOS
import AOS from 'aos';
// Glightbox
import GLightbox from 'glightbox';
// hover video
import hoverVideo from './pagebuilder/hoverVideo';

import scrollSvg from 'scroll-svg';
// Rellax paralax scrolling
import Rellax from 'rellax';
// data highlights
import { startDataHighlights } from './pagebuilder/dataHighlights';

// timeline
import { startTimeline } from './pagebuilder/timeline';

// mouse follow
import { mouseFollow } from './pagebuilder/mouseFollow';

// float labels
import FloatLabels from 'float-labels.js';
// keen slider
import KeenSlider from 'keen-slider';

// plyr
import Plyr from 'plyr';

// mobile navigation
import { initMobileNav } from "./modules/navigation";

// lazysizes
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

window.Alpine = Alpine;

Alpine.data('header', header);
Alpine.plugin(persist);

// state
Alpine.store('modals', {
  filterModal: {
    open: false,

    toggle() {
      this.open = !this.open;
      this.open
        ? (document.body.style.overflowY = 'hidden')
        : (document.body.style.overflowY = '');
    },
  },
});

Alpine.start();

AOS.init();

const lightbox = GLightbox();

const svgPath = document.querySelector('#scroll-line');
if (svgPath !== null) {
  const svg = scrollSvg(svgPath);
}

//select all element with class
const rellaxin = document.querySelectorAll(".rellax");

//iterate through all elements
rellaxin.forEach(el => {

  const rellax = new Rellax(el, {
    relativeToWrapper: true,
    wrapper: el.closest('.scroller'), //parent element with class .scroller
    speed: 3
  });

  window.addEventListener("scroll", () => { // fix to init
    //throttle 
    throttle(rellax.refresh(), 250);

  });

});

//throttle function
let throttleTimer;
const throttle = (callback, time) => {
  if (throttleTimer) return;
  throttleTimer = true;
  setTimeout(() => {

    throttleTimer = false;
  }, time);
}

let floatlabels = new FloatLabels('form', {});

function setReferrer() {
  let referrer = document.referrer;
  let host = window.location.hostname;
  let href = window.location.href;

  // host is part of referrer
  if (referrer.includes(host)) {
    // referrer is not href
    if (referrer != href) {
      localStorage.setItem('referrer', referrer);
    }
  } else {
    localStorage.setItem('referrer', referrer);
  }
}
setReferrer();

document.addEventListener('DOMContentLoaded', function () {

  /**
   * data highlights
   */

  startDataHighlights();

  /*
  * hover videos
  */
  hoverVideo();


  /**
   * timeline
   */
  startTimeline();

  /**
   * NAVIGATION
   */
  initMobileNav();

  /**
   * SLIDER CE 008
   */
  if (document.querySelector('[data-slider]')) {
    function sliderNavigation(slider) {
      let wrapper, arrowLeft, arrowRight;

      function markup(remove) {
        wrapperMarkup(remove);
        arrowMarkup(remove);
      }

      function removeElement(elment) {
        elment.parentNode.removeChild(elment);
      }
      function createDiv(className) {
        var div = document.createElement('div');
        var classNames = className.split(' ');
        classNames.forEach((name) => div.classList.add(name));
        return div;
      }

      function arrowMarkup(remove) {
        if (remove) {
          removeElement(arrowLeft);
          removeElement(arrowRight);
          return;
        }
        arrowLeft = createDiv('arrow arrow--left');
        arrowLeft.addEventListener('click', () => slider.prev());
        arrowRight = createDiv('arrow arrow--right');
        arrowRight.addEventListener('click', () => slider.next());

        wrapper.prepend(arrowLeft);
        wrapper.prepend(arrowRight);
      }

      function wrapperMarkup(remove) {
        if (remove) {
          var parent = wrapper.parentNode;
          while (wrapper.firstChild)
            parent.insertBefore(wrapper.firstChild, wrapper);

          removeElement(wrapper);
          return;
        }
        wrapper = createDiv('navigation-wrapper');
        slider.container.parentNode.appendChild(wrapper);
        wrapper.appendChild(slider.container);
      }

      function enableDragAndLoop() {
        if (slider.options.loop == false || slider.options.drag == false) {
          let newOptions = slider.options;
          newOptions.drag = true;
          newOptions.loop = true;
          slider.update(newOptions);
        }
      }

      function disableDragAndLoop() {
        if (slider.options.loop == true || slider.options.drag == true) {
          let newOptions = slider.options;
          newOptions.drag = false;
          newOptions.loop = false;
          slider.update(newOptions);
        }
      }

      function updateClasses() {
        var slide = slider.track.details.rel;
        var navWrapper = slider.container.parentNode;
        slide === 0
            ? arrowLeft.classList.add('arrow--disabled')
            : arrowLeft.classList.remove('arrow--disabled');
        slide === slider.track.details.slides.length - 1
            ? arrowRight.classList.add('arrow--disabled')
            : arrowRight.classList.remove('arrow--disabled');

        if (
            slider.slides.length <= slider.options.slides.perView &&
            typeof navWrapper != 'undefined'
        ) {
          navWrapper.classList.add('arrows--disabled');
          if (slider.slides.length == slider.options.slides.perView) {
            slider.container.classList.add('justify-content-start');
          } else {
            slider.container.classList.remove('justify-content-start');
          }
          disableDragAndLoop();
        } else if (
            arrowRight.classList.contains('arrow--disabled') &&
            arrowLeft.classList.contains('arrow--disabled') &&
            typeof navWrapper != 'undefined'
        ) {
          navWrapper.classList.add('arrows--disabled');
          slider.container.classList.remove('justify-content-start');
          disableDragAndLoop();
        } else {
          navWrapper.classList.remove('arrows--disabled');
          slider.container.classList.remove('justify-content-start');
          enableDragAndLoop();
        }
      }

      slider.on('created', () => {
        markup();
        updateClasses();
      });
      slider.on('optionsChanged', () => {
        markup(true);
        markup();
        updateClasses();
      });
      slider.on('slideChanged', () => {
        updateClasses();
      });
      slider.on('destroyed', () => {
        markup(true);
      });
    }

    function createNewSlider(node) {
      let slider = new KeenSlider(
          node,
          {
            slides: {
              origin: node.dataset.sliderOrigin ?? 'auto',
              perView: node.dataset.sliderSlides,
              spacing: 15,
            },
            loop: true,
            breakpoints: {
              '(min-width: 1024px)': {
                slides: {
                  origin: node.dataset.sliderOrigin ?? 'auto',
                  perView: node.dataset.sliderSlides,
                  spacing: 15,
                },
              },
              '(max-width: 1024px)': {
                slides: {
                  perView:
                      node.dataset.sliderSlides - 1 >= 1
                          ? node.dataset.sliderSlides - 1
                          : 1,
                  spacing: 15,
                },
              },
              '(max-width: 768px)': {
                slides: {
                  perView:
                      node.dataset.sliderSlides - 2 >= 1
                          ? node.dataset.sliderSlides - 2
                          : 1,
                  spacing: 15,
                },
              },
            },
          },
          [
            sliderNavigation,
            (slider) => {
              let timeout;
              let mouseOver = false;
              function clearNextTimeout() {
                clearTimeout(timeout);
              }
              function nextTimeout() {
                clearTimeout(timeout);
                if (mouseOver) return;
                timeout = setTimeout(() => {
                  slider.next();
                }, 5000);
              }

              // update slide classes
              function updateClasses() {
                let container = slider.container;
                let slideNumber = slider.track.details.rel;
                let activeSlide = slider.slides[slideNumber];

                // toggle slide classes
                slider.slides.forEach((slide) => {
                  slide.classList.remove('keen-slider__slide--active');
                });
                activeSlide.classList.add('keen-slider__slide--active');

                // adaptive height
                if (container.hasAttribute('data-adaptive')) {
                  // container.style.height =
                  //     activeSlide.offsetHeight + 'px';
                }
              }

              slider.on('created', () => {
                updateClasses();
                slider.container.addEventListener('mouseover', () => {
                  mouseOver = true;
                  clearNextTimeout();
                });
                slider.container.addEventListener('mouseout', () => {
                  mouseOver = false;
                  nextTimeout();
                });
                nextTimeout();
              });
              slider.on('dragStarted', clearNextTimeout);
              slider.on('animationEnded', nextTimeout);
              slider.on('updated', nextTimeout);
              slider.on('slideChanged', () => {
                updateClasses();
              });
              slider.on('created', () => {
                slider.container.classList.add('keen-slider--loaded');
              });
            },
            (slider) => {
              if (
                  slider.container == document.querySelector('[data-slider="0"]')
              ) {
                let mainNavToggle = document.querySelector('.toggle-main-nav');
                if (mainNavToggle !== null) {
                  mainNavToggle.addEventListener('click', () => {
                    setTimeout(() => {
                      window.dispatchEvent(new Event('resize'));
                    }, 50);
                  });
                }

              }
            },
          ]
      );
    }

    document.querySelectorAll('[data-slider]').forEach((e) => {
      createNewSlider(e);
    });
  }

  mouseFollow();

  /**
   * Content-Tabs CE 010
   */
  if (document.querySelector('[data-content-tabs]')) {
    function contentTabsNavigation(slider) {
      let wrapper, arrowLeft, arrowRight;

      function markup(remove) {
        wrapperMarkup(remove);
        arrowMarkup(remove);
      }

      function removeElement(elment) {
        elment.parentNode.removeChild(elment);
      }
      function createDiv(className) {
        var div = document.createElement('div');
        var classNames = className.split(' ');
        classNames.forEach((name) => div.classList.add(name));
        return div;
      }

      function arrowMarkup(remove) {
        if (remove) {
          removeElement(arrowLeft);
          removeElement(arrowRight);
          return;
        }
        arrowLeft = createDiv('arrow arrow--left');
        arrowLeft.addEventListener('click', () => slider.prev());
        arrowRight = createDiv('arrow arrow--right');
        arrowRight.addEventListener('click', () => slider.next());

        wrapper.appendChild(arrowLeft);
        wrapper.appendChild(arrowRight);
      }

      function wrapperMarkup(remove) {
        if (remove) {
          var parent = wrapper.parentNode;
          while (wrapper.firstChild)
            parent.insertBefore(wrapper.firstChild, wrapper);
          removeElement(wrapper);
          return;
        }
        wrapper = createDiv('navigation-wrapper');
        slider.container.parentNode.appendChild(wrapper);
        wrapper.appendChild(slider.container);
      }

      function updateClasses() {
        var slide = slider.track.details.rel;
        slide === 0
          ? arrowLeft.classList.add('arrow--disabled')
          : arrowLeft.classList.remove('arrow--disabled');
        slide === slider.track.details.slides.length - 1
          ? arrowRight.classList.add('arrow--disabled')
          : arrowRight.classList.remove('arrow--disabled');
      }

      slider.on('created', () => {
        markup();
        updateClasses();
      });
      slider.on('optionsChanged', () => {
        markup(true);
        markup();
        updateClasses();
      });
      slider.on('slideChanged', () => {
        updateClasses();
      });
      slider.on('destroyed', () => {
        markup(true);
      });
    }

    function AdaptiveHeight(slider) {
      let firstHeightUpdate = 0;
      let padding = 64;
      let throttlePause;

      function throttle(callback, time) {
        if (throttlePause) return;

        throttlePause = true;
        setTimeout(() => {
          callback();
          throttlePause = false;
        }, time);
      }

      function getWidth() {
        return Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
        );
      }

      function updateHeight() {
        if (getWidth() >= 768) {
          padding = 64;
        } else {
          padding = 24;
        }
        if (firstHeightUpdate == 0) {
          setTimeout(() => {
            slider.container.style.height =
              slider.slides[slider.track.details.rel].querySelector('.d-flex')
                .offsetHeight +
              2 * padding +
              'px';
          }, 500);
          firstHeightUpdate = 1;
        }
        slider.container.style.height =
          slider.slides[slider.track.details.rel].querySelector('.d-flex')
            .offsetHeight +
          2 * padding +
          'px';
      }

      slider.on('created', updateHeight);
      slider.on('slideChanged', updateHeight);
      window.addEventListener('resize', () => {
        throttle(updateHeight, 250);
      });
    }

    function ThumbnailPlugin(main) {
      return (slider) => {
        function removeActive() {
          slider.slides.forEach((slide) => {
            slide.querySelector('h3').classList.remove('active');
          });
        }
        function addActive(idx) {
          slider.slides[idx].querySelector('h3').classList.add('active');
        }

        function addClickEvents() {
          slider.slides.forEach((slide, idx) => {
            slide.addEventListener('click', () => {
              if (idx > slider.track.details.maxIdx) {
                slider.moveToIdx(slider.track.details.maxIdx);
              }
              slider.moveToIdx(idx);
              main.moveToIdx(idx);
            });
          });
        }

        slider.on('created', () => {
          addActive(slider.track.details.rel);
          addClickEvents();
          main.on('animationStarted', (main) => {
            removeActive();
            const next = main.animator.targetIdx || 0;
            addActive(main.track.absToRel(next));
            slider.moveToIdx(next);
          });
        });
      };
    }

    function createNewContentTabsSlider(e) {
      const mainSlider = new KeenSlider(e, {}, [
        contentTabsNavigation,
        AdaptiveHeight,
      ]);
      const thumbnailElement = e
        .closest('.site-section--content-tabs')
        .querySelector('[data-content-tabs-thumbnails]');
      const thumbnails = new KeenSlider(
        thumbnailElement,
        {
          loop: false,
          slides: {
            perView: 'auto',
            spacing: 48,
          },
          range: {
            align: false,
          },
        },
        [ThumbnailPlugin(mainSlider)]
      );
    }

    document.querySelectorAll('[data-content-tabs]').forEach((e) => {
      createNewContentTabsSlider(e);
    });
  }

  /**
   * LOGO STRIPE CE 012
   */
  if (document.querySelector('[data-logo-slider]')) {
    function createNewSlider(node) {
      const animation = { duration: 30000, easing: (t) => t };

      let slider = new KeenSlider(
        node,
        {
          slides: {
            perView: 'auto',
            origin: 'auto',
            spacing: 60,
          },
          loop: true,
          renderMode: 'performance',
          breakpoints: {
            '(min-width: 1024px)': {
              slides: {
                perView: 5,
                origin: 'auto',
                spacing: 60,
              },
            },
            '(max-width: 1024px)': {
              slides: {
                perView: 3,
                origin: 'auto',
                spacing: 20,
              },
            },
            '(max-width: 768px)': {
              slides: {
                perView: 2,
                origin: 'auto',
                spacing: 20,
              },
            },
          },
          drag: false,
          created(s) {
            s.moveToIdx(5, true, animation);
          },
          updated(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
          },
          animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
          },
        },
        []
      );
    }

    document.querySelectorAll('[data-logo-slider]').forEach((e) => {
      createNewSlider(e);
    });
  }

  /**
   * Video CE 015
   */
  if (document.querySelector('[data-video]')) {
    function createNewVideo(node) {
      const root = document.documentElement;
      const bigPlayButton = document.createElement('div');
      const plSpan = document.createElement('span');
      const aySpan = document.createElement('span');
      plSpan.innerHTML = 'PL';
      aySpan.innerHTML = 'AY';
      bigPlayButton.classList.add('plyr_play--big');

      let isPlayerClicked = 0;
      let preview = node.getAttribute('data-preview');

      // initiate Plyr player
      const player = new Plyr(node, {
        muted: true,
        hideControls: true,
        controls: ['play', 'progress', 'mute', 'volume', 'fullscreen'],
        /* control options: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'] */
      });
      player.on('ready', () => {
        player.pause();
      });

      const playerDiv = node.closest('.plyr');

      // data preview attribute
      if (preview == 'true') {
        playerDiv.appendChild(bigPlayButton);
        bigPlayButton.appendChild(plSpan);
        bigPlayButton.appendChild(aySpan);

        // pause after 10 seconds of autoplay
        player.on('timeupdate', () => {
          if (isPlayerClicked == 0) {
            if (player.currentTime >= 9) {
              node.style.opacity = 0;
            }
            if (player.currentTime >= 10) {
              player.currentTime = 0;
              player.play();
              node.style.opacity = 1;
            }
          }
        });

        // restart video on first click and make options visible, also give player an additional class so the big play button won't reappear
        playerDiv.addEventListener('click', () => {
          isPlayerClicked += 1;
          if (isPlayerClicked == 1) {
            player.restart();
            player.volume = 1;
            player.play();
            playerDiv.classList.add('player-clicked');
          }
          let playerControls = node
            .closest('.plyr')
            .querySelector('.plyr__controls');
          playerControls.style.opacity = 1;
          playerControls.style.visibility = 'visible';
          node.closest('.plyr').style.cursor = 'auto';
        });

        // start autoplay if element is 100% in viewport
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                if (player.currentTime < 10 && isPlayerClicked == 0) {
                  player.volume = 0;
                  player.muted = true;
                  node.style.opacity = 1;
                  player.on('ready', () => {
                    player.play();
                  });
                }
              }
            });
          },
          { threshold: 1 }
        );
        observer.observe(playerDiv);

        // move big play button to mouse position
        playerDiv.addEventListener('mousemove', function (e) {
          var left =
            e.clientX -
            playerDiv.getBoundingClientRect().left -
            bigPlayButton.offsetWidth / 2 +
            'px';
          var top =
            e.clientY -
            playerDiv.getBoundingClientRect().top -
            bigPlayButton.offsetHeight / 2 +
            'px';
          bigPlayButton.style.left = left;
          bigPlayButton.style.top = top;
        });
      } else {
        playerDiv.classList.add('player-clicked');
        let playerControls = node
          .closest('.plyr')
          .querySelector('.plyr__controls');
        playerControls.style.opacity = 1;
        playerControls.style.visibility = 'visible';
        node.closest('.plyr').style.cursor = 'auto';
      }
    }

    document.querySelectorAll('[data-video]').forEach((e) => {
      createNewVideo(e);
    });
  }

  /**
   * Timeline CE 016
   */
  if (document.querySelector('.timeline-container--hidden')) {
    let timelineVertical = document.querySelector('.timeline--vertical');

    document
      .querySelector('.timeline-more')
      .addEventListener('click', function () {
        document
          .querySelectorAll('.timeline-container--hidden')
          .forEach((e) => {
            e.style.setProperty('display', 'block');
          });
        document
          .querySelector('.timeline-more')
          .style.setProperty('display', 'none');
        document
          .querySelector('.timeline-close')
          .style.setProperty('display', 'block');
      });

    document
      .querySelector('.timeline-close')
      .addEventListener('click', function () {
        document
          .querySelectorAll('.timeline-container--hidden')
          .forEach((e) => {
            e.style.setProperty('display', 'none');
          });
        document
          .querySelector('.timeline-more')
          .style.setProperty('display', 'block');
        document
          .querySelector('.timeline-close')
          .style.setProperty('display', 'none');
        timelineVertical.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      });
  }

  /**
   * Image Mask CE 022
   */
  if (document.querySelector('.image-mask')) {
    document.querySelectorAll('.image-mask').forEach((node) => {
      let imageDiv = node.querySelector('.mousemove');
      let imageMaskTop = node.querySelector('.image-mask__image--initial');
      let imagemaskBottom = node.querySelector('.image-mask__image--revealed');
      let root = document.documentElement;

      const addMultipleListeners = (
        el,
        types,
        listener,
        options,
        useCapture
      ) => {
        types.forEach((type) =>
          el.addEventListener(type, listener, options, useCapture)
        );
      };

      addMultipleListeners(imagemaskBottom, ['mousemove', 'touchmove'], (e) => {
        if (imageMaskTop.classList.contains('image-mask__image--hidden')) {
          return;
        }
        let x = e.pageX - imageDiv.offsetLeft;
        let y = e.pageY - imageDiv.offsetTop;
        let xPercent = (x * 100) / imageDiv.offsetWidth;
        let yPercent = (y * 100) / imageDiv.offsetHeight;
        root.style.setProperty('--image-mask-x', xPercent + '%');
        root.style.setProperty('--image-mask-y', yPercent + '%');
        root.style.setProperty('--image-mask-opacity', 1);
      });

      addMultipleListeners(imagemaskBottom, ['mouseleave', 'touchend'], (e) => {
        if (imageMaskTop.classList.contains('image-mask__image--hidden')) {
          return;
        }
        root.style.setProperty('--image-mask-opacity', 0);
      });

      // toggle visiblility
      imageDiv.addEventListener('click', (e) => {
        imageMaskTop.classList.toggle('image-mask__image--hidden');
        imagemaskBottom.classList.toggle('image-mask__image--visible');
        if (imageMaskTop.classList.contains('image-mask__image--hidden')) {
          root.style.setProperty('--image-mask-opacity', 1);
        } else {
          root.style.setProperty('--image-mask-opacity', 0);
        }
      });
    });
  }

  /**
   * Media Tabs Scroll To Active Tab
   */
  if (document.querySelector('.page--media')) {
    let activeLink = document.querySelector('.media__tabs span.active');
    setTimeout(function () {
      activeLink.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }, 500);

    let links = document.querySelectorAll('.media__tabs span');
    links.forEach((e) => {
      e.addEventListener('click', function () {
        let activeLink = document.querySelector('.media__tabs span.active');
        if (!e.classList.contains('active')) {
          activeLink.classList.remove('active');
          e.classList.add('active');
        }
      });
    });
  }

  /**
   *  Job Listing
   */
  if (document.querySelector('.include-partial--joblisting')) {
    window.__BiteJobsApiV4Loaded = function (Api) {
      // toggle function
      function toggleArrayItem(a, v) {
        const i = a.indexOf(v);
        if (i === -1) a.push(v);
        else a.splice(i, 1);
      }

      // BITE presentation API key
      const api = new Api({
        key: 'b9db55329aa245397a97433dd932fb5a71aeb6db',
      });

      // Als tabelle Rendern
      const renderer = new Api.renderer.Table({
        attributes: {
          table: function (attrs) {
            attrs.classes['col-12'] = true;
            return attrs;
          },
          headCell: function (attrs, column) {
            if (column.id == 'title') {
              attrs.classes['col-md-8'] = true;
            }
            if (column.id == 'customField2') {
              attrs.classes['col-md-4'] = true;
            }
            return attrs;
          },
        },
        // Tabelle in dieses Element rendern
        element: '#job-postings',
        // Reihenfolge und Bezeichnung der Spalten. Wird das label-Attribut weggelassen, wird die Bezeichnung die der Server liefert, verwendet.
        columns: [
          {
            id: 'title',
            label: 'Job',
          },
          {
            id: 'customField2',
            label: 'Location',
          },
        ],
        // Wenn keine Ergebnisse gefunden worden sind, zeige diese Info an.
        noResults: '{{ entry.noResultsText }}',
        valueFormatters: {
          customField2: Api.formatter.Link(),
        },
      });

      // nicht gesetzt oder leer = standard config
      const config = {
        filters: {
          // ?ort=
          customField2: Api.utils.queryParam('ort'),
        },
      
      };
      // Erlaubt einfaches steuern der Daten und rendert die Tabelle neu
      const ctrl = new Api.TableControl(api, renderer, config);
      ctrl.reload();

      function resetFilter() {
        document.querySelectorAll('#locations .location p').forEach((e) => {
          e.classList.remove('active');
        });
        document.querySelectorAll('#entrylevels .entrylevel p').forEach((e) => {
          e.classList.remove('active');
        });
        filterEntryLevelArr = [];
        filterLocationArr = [];
        ctrl.reset().reload();
      }

      document
        .querySelector('.reset-jobfilter')
        .addEventListener('click', resetFilter);

      let filterEntryLevelArr = [];

      const filterEntryLevel = function (entrylevel) {
        let entrylevels = document.querySelectorAll(
          '#entrylevels .entrylevel p'
        );
        entrylevel.target.classList.toggle('active');
        toggleArrayItem(filterEntryLevelArr, entrylevel.target.innerHTML);
        ctrl.filter('customField1', filterEntryLevelArr);
      };

      // einstiegslevel
      const entryLevelRenderer = new Api.renderer.Template({
        element: '#entrylevels',
        template: function (h, jobPostings) {
          let entries = [];
          let einstiegslevel = [];

          Api.utils.forEach(jobPostings, function (entry) {
            if (
              !einstiegslevel.includes(entry.customField1.formatted().text) &&
              entry.customField1.formatted().text != undefined
            ) {
              var entryEl = h(
                'div.entrylevel',
                {
                  key: entry.id.raw,
                },
                [
                  h(
                    'p.entrylevel-text.btn.btn--search-tag',
                    {
                      onclick: filterEntryLevel,
                    },
                    [entry.customField1.formatted().text]
                  ),
                ]
              );

              einstiegslevel.push(entry.customField1.formatted().text);

              entries.push(entryEl);
            }
          });

          return h('div', entries);
        },
      });

      const entryLevelControl = new Api.Control(api, entryLevelRenderer, {});
      entryLevelControl.reload();

      let filterLocationArr = [];

      const filterLocation = function (location) {
        let locations = document.querySelectorAll('#locations .location p');
        location.target.classList.toggle('active');
        toggleArrayItem(filterLocationArr, location.target.innerHTML);
        ctrl.filter('customField2', filterLocationArr);
      };

      // standorte
      const locationRenderer = new Api.renderer.Template({
        element: '#locations',
        template: function (h, jobPostings) {
          let entries = [];
          let locations = [];

          Api.utils.forEach(jobPostings, function (entry) {
            if (
              !locations.includes(entry.customField2.formatted().text) &&
              entry.customField2.formatted().text != undefined
            ) {
              const entryEl = h(
                'div.location',
                {
                  key: entry.id.raw,
                },
                [
                  h(
                    'p.btn.btn--search-tag',
                    {
                      onclick: filterLocation,
                    },
                    [entry.customField2.formatted().text]
                  ),
                ]
              );

              locations.push(entry.customField2.formatted().text);

              entries.push(entryEl);
            }
          });

          return h('div', entries);
        },
      });

      const locationControl = new Api.Control(api, locationRenderer, {});
      locationControl.reload();

      document
        .querySelector('.save-jobfilter')
        .addEventListener('click', function () {
          ctrl.reload();
        });
    };
  }

  /**
   * Newsletter Frontend-Validation
   */
  if (document.querySelector('.include-partial--newsletter')) {
    // All required fields as array
    const requiredFields = Array.from(
      document.querySelectorAll(
        '.include-partial--newsletter .cr_form-component.musthave input'
      )
    );

    // Input fields
    const inputFields = document.querySelectorAll(
      '.include-partial--newsletter .cr_form-component.musthave .cr_form-input'
    );
    if (inputFields) {
      inputFields.forEach((e) => {
        e.required = true;
        e.addEventListener('blur', (k) => {
          if (k.target.value == null) {
            e.classList.add('clever_form_error');
          } else if (k.target.value.trim() == '') {
            e.classList.add('clever_form_error');
          } else {
            e.classList.remove('clever_form_error');
          }
        });
      });
    }

    // GDPR Checkbox
    const gdprCheckbox = document.querySelector(
      '.include-partial--newsletter .cr_form-component.musthave .data-privacy .cr_ipe_checkbox'
    );
    if (gdprCheckbox) {
      gdprCheckbox.required = true;
    }

    // On valid submit check reCaptcha
    const submitButton = document.querySelector(
      '.include-partial--newsletter .cr_form-component--submit'
    );
    submitButton.addEventListener('click', function () {
      if (requiredFields.every((e) => e.validity.valid)) {
        if (grecaptcha.getResponse().length === 0) {
          document
            .querySelector('.include-partial--newsletter .g-recaptcha')
            .scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    });
  }

  /**
   * Webinare Events Countdown
   */
  if (
    document.querySelector(
      '.page--single-event.site-section--data-highlights'
    )
  ) {
    const initialDays = document.querySelector('[data-countdown="days"] div.headline p');
    const initialHours = document.querySelector('[data-countdown="hours"] div.headline p');
    const initialMinutes = document.querySelector(
      '[data-countdown="minutes"] div.headline p'
    );
    const initialSeconds = document.querySelector(
      '[data-countdown="seconds"] div.headline p'
    );
    const countdownDate = document.querySelector('[data-countdowntime]').dataset
      .countdowntime;

    const x = setInterval(function () {
      let dateNow = Math.floor(new Date().getTime() / 1000);
      let distance = countdownDate - dateNow;

      if (distance < 0) {
        clearInterval(x);
        return;
      }

      let days = Math.floor(distance / (60 * 60 * 24));
      let hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
      let minutes = Math.floor((distance % (60 * 60)) / 60);
      let seconds = Math.floor(distance % 60);

      initialDays.innerHTML = days;
      initialHours.innerHTML = hours;
      initialMinutes.innerHTML = minutes;
      initialSeconds.innerHTML = seconds;
    }, 1000);
  }

  /**
   * Accordion close all other details on details click
   */
  if (document.querySelector('.site-section--accordion')) {
    const allDetails = document.querySelectorAll(
      '.site-section--accordion details'
    );
    allDetails.forEach((e) => {
      e.addEventListener('click', () => {
        allDetails.forEach((k) => {
          if (k != e) {
            if (k.attributes.open) {
              k.attributes.removeNamedItem('open');
            }
          }
        });
      });
    });
  }

  /**
   * Add "open Cookie Banner" click event
   */
  // if (document.querySelector('.open-cookie-layer')) {
  //   const openCookieLayerLinks =
  //     document.querySelectorAll('.open-cookie-layer');
  //   openCookieLayerLinks.forEach((e) => {
  //     e.addEventListener('click', () => {
  //       document
  //         .getElementById('elc-cookie-consent')
  //         .classList.toggle('elc-hidden');
  //       document
  //         .getElementById('elc-cookie-tab')
  //         .classList.toggle('elc-hidden');
  //     });
  //   });
  // }

  /*
  * Cookiebot integration
  */


});


// cookiebot loaded
window.addEventListener('CookiebotOnLoad', function (e) {

  // open cookieBot layer
  let cb = document.querySelectorAll('.open-cb-layer');
  cb.forEach(function (item) {
    item.addEventListener('click', function () {
      Cookiebot.show();
    });
  });

});