import Headroom from 'headroom.js';

export default () => ({
    headroom: {},
    loaded: false,
    open: false,
    layer: {
        showMainNav: false,
        showSearch: false,
        showLang: false,
    },
    init() {

        // window.scrollTo(0,0);
        this.loaded = true;

        this.$nextTick(() => {

            const header = document.querySelector('.site-header');
            this.headroom = new Headroom(header);
            this.headroom.init();

        });

    },
    toggle(event) {

        let body = document.body;
        let target = event.target;

        // main nav
        if (target.classList.contains('toggle-main-nav')) {
            this.layer.showMainNav = !this.layer.showMainNav;
            this.layer.showSearch = false;
            this.layer.showLang = false;
        }

        // search
        if (target.classList.contains('toggle-search')) {
            this.layer.showMainNav = false;
            this.layer.showSearch = !this.layer.showSearch;
            this.layer.showLang = false;
        }

        // lang
        if (target.classList.contains('toggle-lang')) {
            this.layer.showMainNav = false;
            this.layer.showSearch = false;
            this.layer.showLang = !this.layer.showLang;
        }

        let closed = Object.values(this.layer).every(
            value => value === false
        );

        if (!closed) {
            body.classList.add('no-scroll');
            this.open = true;
            this.headroom.freeze();
        } else {
            body.classList.remove('no-scroll');
            this.open = false;
            this.headroom.unfreeze();
        }

    }
})