
function hoverVideo() {

    let slide = document.querySelectorAll('.keen-slider__slide');

    slide.forEach(slide => {

        let video = slide.querySelector('.hover-video');
        if(video === null) return;

        slide.addEventListener('mouseover', function (e) {
            video.play();
        });
        slide.addEventListener('mouseout', function () {
            video.pause();
            // this.currentTime = 0;
        });

    });

}

export default hoverVideo;