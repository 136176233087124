function initMobileNav() {
    const parents = document.querySelectorAll('.main-nav__nav .parent');
    parents.forEach((parent) => {
        const plus = document.createElement('div');
        plus.className = 'open-menu';
        plus.addEventListener('click', (evt) => {
            parent.classList.toggle('open');
        }, false);
        parent.querySelector('.nav-link-wrapper').append(plus);
    });
}

export {
    initMobileNav
};