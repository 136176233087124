function mouseFollow() {

    // get follower
    let follower = document.querySelectorAll(".follower");

    follower.forEach(element => {

        let quoteSlider = element.closest(".site-section--quote-slider");
        let hide = document.querySelectorAll(".follower--hide, .dots");
        let slides = quoteSlider.querySelectorAll(".site-section--quote-slider__item");
        // slider arrows
        let leftArrow = quoteSlider.querySelector(".arrow--left");
        let rightArrow = quoteSlider.querySelector(".arrow--right");

        if (slides.length > 1) {
            
            document.addEventListener("mousemove", (e) => {
                setCursorPosition(e, element, quoteSlider);
            });

            // click arrow
            quoteSlider.addEventListener("click", (e) => {

                if(element.classList.contains("follower--left")) {
                    leftArrow.click();
                }
                if(element.classList.contains("follower--right")) {
                    rightArrow.click();
                }
            });


        }

        // hide follower 
        if(hide.length > 0) {

            hide.forEach(h => {

                h.addEventListener("mouseover", (e) => {
                    element.classList.add("follower--hidden");
                });
                h.addEventListener("mouseout", (e) => {
                    element.classList.remove("follower--hidden");
                });

            });
        }

    });

    // set cursor position
    let setCursorPosition = function (e, element, container) {


        let xPosition = e.clientX - element.clientWidth / 2 + "px";
        let yPosition = e.clientY - element.clientHeight / 2 + "px";

        var left = e.clientX - container.getBoundingClientRect().left - element.offsetWidth / 2 + 'px';
        var top = e.clientY - container.getBoundingClientRect().top - element.offsetHeight / 2 + 'px';

        element.style.left = left;
        element.style.top = top;

        if (e.clientX < (container.offsetWidth / 2)) {
            element.classList.remove("follower--right");
            element.classList.add("follower--left");
        } else {
            element.classList.add("follower--right");
            element.classList.remove("follower--left");
        }

    };

}
export { mouseFollow }


