// see stackoverflow post: https://stackoverflow.com/questions/65624603/scroll-listener-stroke-dashoffset-draw-svg-between-range-of-pixels#comment116027913_65624603
// map the interval from scrollStart to scrollEnd as percentage values from 0 to 100
function mapInterval(t, scrollStart, scrollEnd) {
    const a = scrollStart;
    const b = scrollEnd;
    const c = 0;
    const d = 100;
    return c + ((d - c) / (b - a)) * (t - a);
}

function getVisibleSVGElements() {
    const timelineElements = document.querySelectorAll('.site-section--timeline__route div');
    const visibleElements = [].filter.call(timelineElements, (timelineElement) => getComputedStyle(timelineElement).display !== "none"); // filter out elements with display: none;
    return visibleElements;
}

function startTimeline() {
    const resizeHandler = () => {
        const visibleElements = getVisibleSVGElements();
        visibleElements.forEach(visibleElement => {
            const timelineSibling = visibleElement.parentNode.nextElementSibling;
            const container = visibleElement.parentNode.parentNode;
            const timelineHeight = timelineSibling.getBoundingClientRect().height;
            const containerHeight = container.getBoundingClientRect().height;
            const svgElement = visibleElement.querySelector('svg');
            const visibleStyle = getComputedStyle(visibleElement);
            if (containerHeight !== timelineHeight) {
                container.style.height = `${timelineHeight}px`;
            }
            if (visibleStyle.justifyContent === "flex-end") {
                svgElement.style.marginRight = `-${svgElement.clientWidth / 2}px`;
            }
        });
    };
    const scrollHandler = () => {
        const visibleElements = getVisibleSVGElements();
        visibleElements.forEach(visibleElement => {
            const keyVisual = visibleElement.querySelector('.site-section--timeline__key-visual');
            const scrollTop = document.documentElement.scrollTop;
            const windowHeight = document.documentElement.clientHeight;
            const rect = visibleElement.getBoundingClientRect();
            const scrollStart = rect.y + scrollTop - (windowHeight / 2); // minus half the window height
            const scrollEnd = scrollStart + rect.height;
            
            if (scrollTop < scrollStart) {
                keyVisual.style.offsetDistance = "0%";
                return;
            }
            if (scrollTop > scrollEnd) {
                keyVisual.style.offsetDistance = "100%";
                return;
            }
            const scrollPercentage = mapInterval(scrollTop, scrollStart, scrollEnd);
            keyVisual.style.offsetDistance = `${scrollPercentage}%`;
        });
    };
    // run the resize handler a single time, then attach it to the resize event
    resizeHandler();
    // run the scroll handler a single time, then attach it to scroll and resize events
    scrollHandler();
    window.addEventListener('scroll', scrollHandler, false);
    window.addEventListener('resize', () => {
        resizeHandler();
        window.removeEventListener('scroll', scrollHandler, false);
        window.addEventListener('scroll', scrollHandler, false);
    }, false);
    // attach resize handler to lazyloaded events if a timeline image has been lazy loaded
    document.addEventListener('lazyloaded', (e) => {
        if (e.target.classList.contains("site-section--timeline__image")) {
            resizeHandler();
        }
    });
}

export { startTimeline };