import counterUp from 'counterup2';

// numbers that should not be counted up towards to
const excludedValues = [0, 1];

const callback = (entries) => {
    entries.forEach(entry => {
        const target = entry.target;
        // on intersection with the viewport, and not if the counter has already been initiated
        if (entry.isIntersecting && !target.classList.contains('is-counting')) {
            // count up if value is not excluded
            if (!excludedValues.includes(Number(target.innerHTML))) {
                counterUp(target, {
                    duration: 1000,
                    delay: 16
                });
            }
            // mark the element as counting
            target.classList.add('is-counting');
        }
    });
};

function startDataHighlights() {
    const highlightedElements = document.querySelectorAll('.site-section--data-highlights:not(.page--single-event) .site-section--data-highlights__data .headline p');
    const observer = new IntersectionObserver(callback, {
        threshold: 0.2 // low threshold as otherwise the element would be prevented from loading for too long
    });
    highlightedElements.forEach(highlightedElement => observer.observe(highlightedElement));
}

export {
    startDataHighlights
};